import { PaginationMetaData } from 'types/pagination';

// PAGE PATH
export const PAGE_PATH = '/payments/configurations';

// Bank Bins Blacklist list
export const CONFIGURATION_LIST_LOAD = 'CONFIGURATION_LIST_LOAD';
export const CONFIGURATION_LIST_SUCCESS = 'CONFIGURATION_SUCCESS';
export const CONFIGURATION_LIST_ERROR = 'CONFIGURATION_ERROR';

// // Bank Bins Blacklist current item
export const CONFIGURATION_CURRENT_LOAD = 'CONFIGURATION_CURRENT_LOAD';
export const CONFIGURATION_CURRENT_SUCCESS = 'CONFIGURATION_CURRENT_SUCCESS';
export const CONFIGURATION_CURRENT_SAVE = 'CONFIGURATION_CURRENT_SAVE';
export const CONFIGURATION_CURRENT_DISCARD = 'CONFIGURATION_CURRENT_DISCARD';
export const CONFIGURATION_CURRENT_SAVE_SUCCESS =
  'CONFIGURATION_CURRENT_SAVE_SUCCESS';
export const CONFIGURATION_CURRENT_UPDATE_SUCCESS =
  'CONFIGURATION_CURRENT_UPDATE_SUCCESS';
export const CONFIGURATION_CURRENT_SAVE_ERROR =
  'CONFIGURATION_CURRENT_SAVE_ERROR';

export const CONFIGURATION_CURRENT_DELETE_SUCCESS =
  'CONFIGURATION_CURRENT_DELETE_SUCCESS';

export interface ConfigurationResponse {
  data: ConfigurationListItem[];
  meta: PaginationMetaData;
}

interface ConfigurationListLoadAction {
  type: typeof CONFIGURATION_LIST_LOAD;
}

interface ConfigurationListSuccess {
  type: typeof CONFIGURATION_LIST_SUCCESS;
  payload: ConfigurationResponse;
}

interface ConfigurationListError {
  type: typeof CONFIGURATION_LIST_ERROR;
}

interface ConfigurationCurrentLoadAction {
  type: typeof CONFIGURATION_CURRENT_LOAD;
}

interface ConfigurationCurrentDeleteAction {
  type: typeof CONFIGURATION_CURRENT_DELETE_SUCCESS;
}

interface ConfigurationCurrentDiscardAction {
  type: typeof CONFIGURATION_CURRENT_DISCARD;
}

interface ConfigurationCurrentSuccess {
  type: typeof CONFIGURATION_CURRENT_SUCCESS;
  data: ConfigurationCurrentResponse;
}

interface ConfigurationCurrentSaveAction {
  type: typeof CONFIGURATION_CURRENT_SAVE;
}

interface ConfigurationCurrentSaveSuccess {
  type: typeof CONFIGURATION_CURRENT_SAVE_SUCCESS;
  data: ConfigurationCurrentResponse;
}

interface ConfigurationCurrentUpdateSuccess {
  type: typeof CONFIGURATION_CURRENT_UPDATE_SUCCESS;
  data: ConfigurationCurrentResponse;
}

interface ConfigurationCurrentSaveError {
  type: typeof CONFIGURATION_CURRENT_SAVE_ERROR;
  data: Error;
}

export type ConfigurationFilters = {
  page: number | string;
};

export interface ConfigurationListItem {
  id: number | string;
  section: string;
  config:
    | ConfigurationRecurringPaymentRecovery
    | ConfigurationDebtCollection
    | object;
  created_at: string;
  updated_at: string;
}

export interface ConfigurationForm {
  section: string;
  config:
    | ConfigurationRecurringPaymentRecovery
    | ConfigurationDebtCollection
    | object;
}

export interface ConfigurationRecurringPaymentRecovery {
  enabled: boolean;
  decline_reasons: string[];
  locales: string[];
  user_categories: number[];
}

export interface ConfigurationDebtCollection {
  enabled: boolean;
  decline_reasons: string[];
  locales: string[];
  user_categories: number[];
  banks_blacklist: string[];
  charge_hours: string[];
}

export interface ConfigurationCurrentResponse {
  data: ConfigurationListItem;
}

export interface ConfigurationState {
  isLoading: boolean;
  hasError: boolean;
  listItems: ConfigurationResponse;
  current: ConfigurationCurrentResponse;
  actionState: {
    configurationSave: {
      isLoading: boolean;
      hasCreated: boolean;
      hasUpdated: boolean;
      hasDeleted: boolean;
      error?: Error;
    };
  };
}

export type ConfigurationActionTypes =
  | ConfigurationListLoadAction
  | ConfigurationListSuccess
  | ConfigurationListError
  | ConfigurationCurrentLoadAction
  | ConfigurationCurrentSuccess
  | ConfigurationCurrentSaveAction
  | ConfigurationCurrentSaveError
  | ConfigurationCurrentDeleteAction
  | ConfigurationCurrentUpdateSuccess
  | ConfigurationCurrentSaveSuccess
  | ConfigurationCurrentDiscardAction;
