import {
  CONFIGURATION_CURRENT_LOAD,
  CONFIGURATION_CURRENT_SAVE,
  CONFIGURATION_CURRENT_SAVE_ERROR,
  CONFIGURATION_CURRENT_SAVE_SUCCESS,
  CONFIGURATION_CURRENT_UPDATE_SUCCESS,
  CONFIGURATION_CURRENT_SUCCESS,
  CONFIGURATION_CURRENT_DELETE_SUCCESS,
  CONFIGURATION_LIST_ERROR,
  CONFIGURATION_LIST_LOAD,
  CONFIGURATION_LIST_SUCCESS,
  ConfigurationActionTypes,
  ConfigurationState,
  CONFIGURATION_CURRENT_DISCARD,
} from './types';

const initialState: ConfigurationState = {
  isLoading: false,
  hasError: false,
  actionState: {
    configurationSave: {
      isLoading: false,
      hasCreated: false,
      hasUpdated: false,
      hasDeleted: false,
    },
  },
  listItems: {
    data: [],
    meta: {
      current_page: 1,
      from: 0,
      to: 0,
      last_page: 1,
      per_page: 15,
      total: 1,
    },
  },
  current: {
    data: {
      id: '',
      section: '',
      config: {
        enabled: false,
        decline_reasons: [],
        locales: [],
        user_categories: [],
      },
      created_at: '',
      updated_at: '',
    },
  },
};

const configurationListReducer = (
  state = initialState,
  action: ConfigurationActionTypes
): ConfigurationState => {
  const defaultState = {
    isLoading: false,
    hasUpdated: false,
    hasCreated: false,
    hasDeleted: false,
  };

  const defaultData = {
    id: '',
    section: '',
    config: {
      enabled: false,
      decline_reasons: [],
      locales: [],
      user_categories: [],
    },
    created_at: '',
    updated_at: '',
  };

  switch (action.type) {
    case CONFIGURATION_LIST_LOAD:
      return {
        ...state,
        current: initialState.current,
        isLoading: true,
        actionState: {
          configurationSave: {
            ...defaultState,
          },
        },
      };

    case CONFIGURATION_CURRENT_LOAD:
    case CONFIGURATION_CURRENT_SAVE:
      return {
        ...state,
        actionState: {
          configurationSave: {
            ...defaultState,
            isLoading: true,
          },
        },
      };
    case CONFIGURATION_CURRENT_DELETE_SUCCESS:
      return {
        ...state,
        actionState: {
          configurationSave: {
            ...defaultState,
            hasDeleted: true,
          },
        },
      };
    case CONFIGURATION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        listItems: action.payload,
      };

    case CONFIGURATION_CURRENT_SUCCESS:
      return {
        ...state,
        current: action.data,
        actionState: {
          configurationSave: {
            ...defaultState,
            error: undefined,
          },
        },
      };

    case CONFIGURATION_CURRENT_DISCARD:
      return {
        ...state,
        current: {
          data: defaultData,
        },
        actionState: {
          configurationSave: {
            ...defaultState,
            error: undefined,
          },
        },
      };

    case CONFIGURATION_CURRENT_SAVE_SUCCESS:
      return {
        ...state,
        current: action.data,
        actionState: {
          configurationSave: {
            ...defaultState,
            hasCreated: true,
            error: undefined,
          },
        },
      };

    case CONFIGURATION_CURRENT_UPDATE_SUCCESS:
      return {
        ...state,
        current: action.data,
        actionState: {
          configurationSave: {
            ...defaultState,
            hasUpdated: true,
            error: undefined,
          },
        },
      };

    case CONFIGURATION_CURRENT_SAVE_ERROR:
      return {
        ...state,
        actionState: {
          configurationSave: {
            ...defaultState,
            error: action.data,
          },
        },
      };

    case CONFIGURATION_LIST_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default configurationListReducer;
